/* Css for all the products and product card related components */
.grid-container {
  display: inline-flex;
  flex-direction: column;
  column-count: 2;
  margin: 2rem auto 2rem auto;
  width: 30%;
  background-color: rgba(255, 229, 220, 0.118);
  box-shadow: inset 0 0 0 3000px rgb(255 255 255 / 30%);
  border-radius: 10%;
  height: 510px;
  width: 300px;
  min-width: 300px;
  margin: 2%;
  min-height: 510px;
}
.grid-container:hover {
  background-color: rgba(255, 229, 220, 0.5);
  transform: scale(1.02);
}

.grid-item {
  margin-bottom: 1%;
}

.grid-item-5 {
  white-space: nowrap;
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: auto;
}

.grid-item-1 {
  white-space: nowrap;
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: auto;
}

section.grid-item-4 img {
  border-radius: 10%;
}

/* Added this to make the section with text on the product card more readable  */
.grid-info {
  color: #5e5e5e;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10%;
}

.purchase-button {
  background-color: dimgrey;
  border-radius: 25%;
  margin-top: 5px;
}

/* CSS for the container that houses the edit and delete buttons */
.control-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-button {
  right: 16px;
  width: 7%;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin: 5px;
}

.edit {
  background-color: #767776;
}

.delete {
  background-color: #767776;
  color: beige;
}
.delete > a {
  color: red;
}

.delete > a:visited {
  color: red;
}

.control-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}
/* Ensuring that the links do not change color if they are clicked  */
a {
  text-decoration: none;
  color: #ffffffc4;
}
a:visited {
  color: #ffffffc4;
}
