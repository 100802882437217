/* CSS for the nav bar  */
.navbar {
  position: fixed;
  top: 0;
  left: 2%;
  right: 2%;
  height: 7%;
  width: 96%;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}
.navbar-button {
  padding: 5px 5px;
  margin: 10px 25px;
  background-color: rgb(202 113 138 / 58%);
  border-radius: 10px;
  min-width: 50px;
  width: 50px;
}

.navbar-auth {
  display: flex;
  margin-left: 60%;
  align-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1200px) {
  .navbar-auth {
    margin-left: 30%;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-auth {
    margin-left: 0%;
  }
}

.log-button {
  background-color: rgb(202 113 138 / 58%);
  border-radius: 10px;
}
.search-back {
  width: fit-content;
  padding: 2%;
}
