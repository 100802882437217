/* Importing all the component CSS  */
@import "./css/_spinner.css";
@import "./css/_pagination.css";
@import "./css/_product.css";
@import "./css/_form.css";
@import "./css/_nav.css";
@import "./css/_scrollbar.css";

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

body {
  position: fixed;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
  /* background-image: url(https://www.yukoshut.eu/hubfs/backgrounds/Serengeti_sunset-1001.jpg); */
  /* background-image: url(https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4wEaT); */
  background-image: url("./img/bkg.jpg");
  background-size: cover;
  font-family: "Roboto", sans-serif;
}
.container {
  width: 96%;
  align-items: center;
  height: 92%;
  position: fixed;
  bottom: 0;
  left: 2%;
  right: 2%;
  overflow: scroll;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(5%);
  backdrop-filter: blur(5%);
  border-radius: 2%;
}

.info-inner {
  margin: auto;
  background-color: rgba(255, 229, 220, 0.5);
  color: #2f2f2c;
  border-radius: 10%;
}
