/* CSS for the pagination for the products */
.pagination {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  color: rgb(89, 26, 29);
  width: 80%;
  border-radius: 25%;
}

.pagination-buttons {
  padding: 0.5%;
  margin-right: 5%;
  margin-left: 5%;
  background-color: rgb(202 113 138 / 58%);
  border-radius: 55%;
  width: 10%;
  font-size: xx-large;
}
.pagination-buttons:hover {
  cursor: pointer;
  background-color: rgb(202 113 138 / 75%);
  color: rgb(223, 207, 143);
  transform: scale(1.1);
}
