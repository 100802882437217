/* Css for all the forms on the app  */

#create-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  top: 15%;
  position: fixed;
  margin: auto;
  left: 30%;
  right: 30%;
}

@media only screen and (max-width: 600px) {
  #create-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    top: 15%;
    position: fixed;
    margin: auto;
    left: 0%;
    right: 0%;
  }
}

.form-item {
  padding: 1%;
  display: flex;
  align-content: space-around;
  justify-content: flex-end;
  margin-bottom: 2%;
  border-radius: 10%;
}
.form-btn {
  border-radius: 15%;
  margin-left: 2%;
  margin-right: 2%;
}
.form-btn:hover {
  cursor: pointer;
  background-color: cadetblue;
}

.update-form-container {
  background-color: rgb(255 255 255 / 58%);
  color: #1f1e1e;
}

.form-buttons {
  display: flex;
  margin: auto;
}
